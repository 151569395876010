<template>
	<div id="nav" :class="devMode ? 'dev-mode' : ''">
		<nav aria-label="main menu">
			<ul>
				<li>
					<router-link title="Home" to="/" @click="toggleNavBar()">Home</router-link>
				</li>
				<li
					v-if="
						(reporterState?.isSysadmin || reporterState?.isSiteAdmin || reporterState?.isReporter) && !reporterState?.permissionsSuspended
					"
				>
					<router-link title="Cashier Shift Summary Report" to="/cashierShiftSummaryReport" @click="toggleNavBar()"
						>Cashier Shift Summary Report</router-link
					>
				</li>
				<li v-if="reporterState?.isSysadmin && !reporterState?.permissionsSuspended">
					<router-link title="Game Performance" to="/gamePerformance" @click="toggleNavBar()">Game Performance</router-link>
				</li>
				<li v-if="(reporterState?.isSysadmin || reporterState?.isReporter) && !reporterState?.permissionsSuspended">
					<router-link title="Unified Sales Report" to="/unifiedSalesReport" @click="toggleNavBar()">Unified Sales Report</router-link>
				</li>
				<li v-if="(reporterState?.isSysadmin || reporterState?.isReporter) && !reporterState?.permissionsSuspended">
					<router-link title="Liability Report" to="/liabilityReport" @click="toggleNavBar()">Liability Report</router-link>
				</li>
				<li v-if="reporterState?.isSysadmin">
					<router-link title="Id Check Report" to="/idCheckReport" @click="toggleNavBar()">Id Check Report</router-link>
				</li>
				<li v-if="devMode">
					<router-link title="System Info" to="/systemInfo" @click="toggleNavBar()">SystemInfo</router-link>
				</li>
				<li>
					<router-link title="About" to="/about" @click="toggleNavBar()">About</router-link>
				</li>
				<li v-if="isMobile && reporterState?.isLoggedOn">
					<button class="btn" title="Logout" target="_blank" @click="logout()">Logout</button>
				</li>
			</ul>
			<span id="loading-icon"></span>
			<p v-if="devMode" class="dev-mode">Client running in development mode</p>
		</nav>
		<div class="casino-name">
			<p>{{ reporterState?.casinoName }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "MainNavbar",
	props: {
		reporterState: Object,
		isMobile: Boolean,
	},
	methods: {
		toggleNavBar() {
			this.eventBus.emit("toggleNavBar");
		},
		logout() {
			this.eventBus.emit("regularLogout");
			this.eventBus.emit("toggleNavBar");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dev-mode {
	position: absolute;
	bottom: 30px;
	width: 100%;
	color: #f00;
	text-align: center;
	font-weight: bold;
}

.casino-name {
	position: relative;
	bottom: 0;
	text-align: center;
}

#nav-container::after {
	content: "<";
	background: #fff;
	color: #000;
	padding: 7px 10px;
	border-radius: 100%;
	position: absolute;
	z-index: 100;
	top: calc(100vh - 50vh - 90px);
	left: calc(15% - 30px);
	line-height: 1;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
	/* overflow-wrap: anywhere; */
	text-overflow: ellipsis;
	overflow: hidden;
}

#nav a.router-link-exact-active {
	font-weight: normal;
	color: #fff;
}

#nav {
	position: fixed;
	top: 80px;
	left: 0;
	bottom: 0;
	max-height: 0;
	width: 100%;
	background-color: #bccfe5;
	overflow: hidden auto;
	list-style: none;
	padding: 0;
	transition: max-height 0.3s;
	z-index: 100;
	box-shadow: 0px 3px 3px rgb(0 0 0 / 50%);
}

#nav.open {
	max-height: 360px;
}

#nav.open.dev-mode {
	max-height: 500px;
}

@media (min-width: 768px) {
	#nav {
		position: relative;
		top: unset;
		width: 15%;
		float: left;
		height: calc(100vh - 130px);
		max-height: unset;
		box-shadow: 1px 0px 6px rgb(0 0 0 / 50%);
		z-index: unset;
	}
}

#nav a {
	font-weight: bold;
}

nav ul {
	display: flex;
	flex-flow: column nowrap;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
}

nav a {
	display: block;
	background-color: #5b88c0;
	margin: 10px;
	padding: 10px;
	border-radius: 6px;
	text-decoration: none;
	transition: 0.3s;
	color: #dceeff;
	user-select: none;
	cursor: pointer;
	box-shadow: inset -1px -1px 15px 0px rgb(0 0 0 / 40%);
}

nav a:hover,
.login-link:hover {
	transform: scale(1.025);
	background-color: #4971a4;
	color: white;
}

.router-link-active,
.router-link-active:hover {
	transform: scale(1);
	background-color: green;
	box-shadow: inset 0px 0px 14px rgb(0 0 0 / 44%);
	cursor: default;
}

.casino-name {
	color: #000;
}

@media (min-width: 768px) {
	#nav.open,
	#nav.open.dev-mode {
		max-height: unset;
	}
}
</style>
