import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Reports Home'
    }
  },
  {
    path: "/cashierShiftSummaryReport",
    name: "CashierShiftSummaryReport",
    component: () =>
      import(/* webpackChunkName: "cashierShiftSummaryReport" */ "../views/CashierShiftSummaryReport.vue"),
    meta: {
      title: 'Cashier Shift Summary Report'
    }
  },
  {
    path: "/gamePerformance",
    name: "GamePerformance",
    component: () =>
      import(/* webpackChunkName: "gamePerformance" */ "../views/GamePerformance.vue"),
    meta: {
      title: 'Game Performance'
    }
  },
  {
    path: "/unifiedSalesReport",
    name: "UnifiedSalesReport",
    component: () =>
      import(/* webpackChunkName: "unifiedSalesReport" */ "../views/UnifiedSalesReport.vue"),
    meta: {
      title: 'Unified Sales Report'
    }
  },
  {
    path: "/liabilityReport",
    name: "LiabilityReport",
    component: () =>
      import(/* webpackChunkName: "liabilityReport" */ "../views/LiabilityReport.vue"),
    meta: {
      title: 'Liability Report'
    }
  },
  {
    path: "/idCheckReport",
    name: "IdCheckReport",
    component: () =>
      import(/* webpackChunkName: "idCheckReport" */ "../views/IdCheckReport.vue"),
    meta: {
      title: 'Id Check Report'
    }
  },
  {
    path: "/systemInfo",
    name: "SystemInfo",
    component: () =>
      import(/* webpackChunkName: "systemInfo" */ "../views/SystemInfo.vue"),
    meta: {
      title: 'System Info | Development Only'
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: 'About Report App | Documentation'
    }
  },
];


// Use ./ as the routing base if we're in dev mode, or if the env variables
// say this app has a subdomain of its own.  Otherwise assume it's running
// in the ./report/ directory.
const useSubdirRouting = (process.env.VUE_APP_WEBCONFIG_TYPE !== 'subdomain') &&
  (process.env.NODE_ENV !== 'development');

const router = createRouter({
  history: createWebHistory(useSubdirRouting ? './report/' : './'),
  routes,
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that was defined above.
  let title = to.meta.title;
  // If the route has a title, use it as the page title. If not, we'll just title it Report App.
  document.title = title ? title : "Report App";
  // Continue resolving the route
  next();
});

export default router;
